import { createApp } from 'vue'
import App from './App.vue'
import { Quasar } from 'quasar';
import 'quasar/dist/quasar.css';
import '@quasar/extras/material-icons/material-icons.css';
import '@/assets/scss/tailwind.scss'
import "@/assets/libs/@mdi/font/css/materialdesignicons.min.css";
import '@/assets/libs/@iconscout/unicons/css/line.css'
import router from './router';
import { createPinia } from 'pinia';

const app = createApp(App)

app.use(router);
app.use(createPinia());
app.use(Quasar, {
  config: {
    icons: {
      iconSet: 'material-icons',
    },
  }
});
app.mount('#app');