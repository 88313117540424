import { createWebHistory, createRouter } from "vue-router";

const routes = [
  {
    path: "/",
    name: "index",
    component: () => import('@/pages/index/index.vue'),
  },
  {
    path: "/client/publication/new",
    name: "register-property",
    component: () => import('@/pages/client/register-property.vue'),
  },
  {
    path: "/client/properties",
    name: "client-properties",
    component: () => import('@/pages/client/properties.vue'),
  },
  {
    path: "/client/messages",
    name: "client-messages",
    component: () => import('@/pages/client/messages.vue'),
  },
  {
    path: "/client/profile",
    name: "client-profile",
    component: () => import('@/pages/client/profile.vue'),
  },
  {
    path: "/properties",
    name: "properties",
    component: () => import('@/pages/listing/grid-view/grid-sidebar.vue'),
  },
  {
    path: "/properties/projects",
    name: "projects",
    component: () => import('@/pages/listing/grid-view/grid-sidebar.vue'),
  },
  {
    path: "/properties/rent",
    name: "rent",
    component: () => import('@/pages/listing/grid-view/grid-sidebar.vue'),
  },
  {
    path: "/properties/sale",
    name: "sale",
    component: () => import('@/pages/listing/grid-view/grid-sidebar.vue'),
  },
  {
    path: "/property-detail",
    name: "property-details",
    component: () => import('@/pages/listing/property-detail/property-detail.vue'),
  },
  {
    path: "/property-detail/:id",
    name: "property-detail",
    component: () => import('@/pages/listing/property-detail/property-detail.vue'),
  },
  // {
  //   path: "/aboutus",
  //   name: "aboutus",
  //   component: () => import('@/pages/pages/aboutus.vue'),
  // },
  {
    path: "/pricing",
    name: "pricing",
    component: () => import('@/pages/pages/pricing.vue'),
  },
  // {
  //   path: "/faqs",
  //   name: "faqs",
  //   component: () => import('@/pages/pages/faqs.vue'),
  // },
  {
    path: "/auth/login",
    name: "auth-login",
    component: () => import('@/pages/pages/auth-pages/auth-login.vue'),
  },
  {
    path: "/auth/signup",
    name: "auth-signup",
    component: () => import('@/pages/pages/auth-pages/auth-signup.vue'),
  },
  {
    path: "/auth/re-password",
    name: "auth-re-password",
    component: () => import('@/pages/pages/auth-pages/auth-re-password.vue'),
  },
  {
    path: "/terms",
    name: "terms",
    component: () => import('@/pages/pages/utility/terms.vue'),
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () => import('@/pages/pages/utility/privacy.vue'),
  },
  {
    path: "/comingsoon",
    name: "comingsoon",
    component: () => import('@/pages/pages/special-pages/comingsoon.vue'),
  },
  {
    path: "/maintenance",
    name: "maintenance",
    component: () => import('@/pages/pages/special-pages/maintenance.vue'),
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import('@/pages/contact.vue'),
  },
  {
    path: "/:404(.*)",
    name: "404",
    component: () => import('@/pages/pages/special-pages/404.vue'),
  },
  
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;